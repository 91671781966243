import React, { FC, useContext, useEffect } from 'react'
import { NavigationContext } from '@/contexts/NavigationContext'
import StoresWrapper from '@/containers/StoresWrapper'
import StoresIndex from '@/components/StoreIndex'
import { StoresContext } from '@/contexts/StoresContext'
import { graphql } from 'gatsby'
import Head from '@/containers/Head'
import { useLocation } from '@reach/router'

const Stores: FC = props => {
  const { setBackButton } = useContext(NavigationContext)
  const { setCenter, setZoom, activeTags } = useContext(StoresContext)

  const location = useLocation()

  useEffect(() => {
    setBackButton(false)

    const urlParams = new URLSearchParams(location.search)
    const tags = urlParams.get('tags')

    if (tags?.includes('luzern') || activeTags.includes('luzern')) {
      setCenter({
        lat: 47.0502,
        lng: 8.30585
      })
    } else {
      setCenter({
        lat: 47.375,
        lng: 8.53
      })
    }

    setZoom(13)
  }, [])

  const { title, meta } = props.data.sanityPage

  return (
    <>
      <Head title={title} meta={meta} />
      <StoresIndex />
    </>
  )
}

Stores.StoresWrapper = StoresWrapper
Stores.isStoreIndex = true

export default Stores

export const query = graphql`
  query {
    sanityPage(title: { eq: "Stores" }) {
      id
      title
      meta {
        ...metaData
      }
      contentModules {
        ...contentModulesData
      }
    }
  }
`
